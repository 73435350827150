import { defu } from 'defu'
import type { Notification } from '#ui/types/notification'

const successNotification: Partial<Notification> = {
  ui: {
    background: 'bg-forgd-green-100 rounded-lg',
    title: 'text-forgd-green-600',
    description: ' text-forgd-green-600',
    rounded: 'rounded-md',
    container: 'border border-forgd-green-600',
    ring: 'ring-0',
    progress: {
      base: 'hidden',
    },
  },
  closeButton: {
    color: 'primary',
    ui: {
      icon: {
        base: 'text-forgd-green-600 w-4.5 h-4.5',
      },
    },
  },
} as const

const errorNotification: Partial<Notification> = {
  color: 'red',
  icon: 'i-heroicons-exclamation-circle',
  ui: {
    background: 'bg-forgd-red-100',
    description: 'text-forgd-red-600',
    progress: {
      base: 'hidden',
    },
    ring: 'ring-forgd-red-600',
    title: 'text-forgd-red-600 font-semibold',
  },
  closeButton: {
    color: 'red',
    variant: 'link',
    ui: {
      variant: {
        link: 'text-forgd-red-600',
      },
    },
  },
}

export function useAppToast() {
  // this may be getting called after the nuxt app context is lost
  const nuxtApp = tryUseNuxtApp()
  if (!nuxtApp) {
    // noop
    const noopHandler = () => {
      if (import.meta.dev) {
        throw new Error('useAppToast() must be called before an async context.')
      }
    }
    return {
      error: noopHandler,
      success: noopHandler,
    }
  }
  const toast = useToast()
  return {
    error(options: string | Partial<Notification>) {
      if (typeof options === 'string') {
        options = { title: options }
      }
      toast.add(defu(options, errorNotification))
    },
    success(options: string | Partial<Notification>) {
      if (typeof options === 'string') {
        options = { title: options }
      }
      toast.add(defu(options, successNotification))
    },
  }
}
